<!-- Parent control for all pop ups. -->

<template>
  <div>
  <!-- use the modal component, pass in the prop -->
    <div class="modal-mask">
      <div class="modal-wrapper d-flex flex-column">
        <div class="pop-up-close">
        <p class="pop-up-close-p cursor-pointer" @click="closePopUp"><span class="fa fa-times"></span></p>   
        </div>
        <div class="modal-container">
          <div class="modal-body">
            <slot>
                <!-- Content goes here -->
            </slot>
          </div>
        </div>
      </div>
    </div>
</div>
</template>
<script>

export default {
    data() {
      return {  
        drag: false
      }
    },
    model: 
    {
        prop: 'showModal',
        event: 'showModalChanged'
    },
    props:
    {
        showModal:
        {
            type: Boolean,
            default: false
        }
    },
    methods: {   
        closePopUp()
        {
            this.$emit("detailsClosed");
        },   
        handleMouseUp(evt) {
            if (!this.drag && evt.target.classList.contains('modal-wrapper')) {
                this.closePopUp();
            }

            this.drag = false;
        }
    },
    mounted() {
        document.addEventListener('mousedown', () => this.drag = false);
        document.addEventListener('mousemove', () => this.drag = true);
        document.addEventListener('mouseup', this.handleMouseUp);
    },
    destroyed() {
        document.removeEventListener('mousedown', () => this.drag = false);
        document.removeEventListener('mousemove', () => this.drag = true);
        document.removeEventListener('mouseup', this.handleMouseUp);
    },
    watch: {
        showModal: function(val)
        {
            if(val)
            {
                document.body.classList.add("modal-open");
            }
            else
            {
                document.body.classList.remove("modal-open");
            }
        }
    }

};
</script>

<style scoped>
    /* Base */
    .modal-mask 
    {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity .3s ease;
    }

    .modal-wrapper 
    {
        display: table-cell;
        vertical-align: middle;
        /* Allow for scrolls on small devices */
        top: 0;
        bottom: 0;
        position: fixed;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
    }

    .modal-container 
    {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 40%;
        min-width: 500px;
        height: auto;
        background-color: #fff;
        transition: all .3s ease;
        border-radius: 8px;
        box-shadow: 0 0 8px 0 #303942;
        margin: 0 auto auto auto;
    }

    .modal-body
    {
        padding: 0;
    }

    /* Close */
    .pop-up-close
    {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: auto 0 0 0;
    }

    .pop-up-close-p
    {
        width: 45%;
        min-width: 550px;
        text-align: right;
        margin-bottom: 0;
    }

    .fa.fa-times
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
    }

</style>