<template>
  <BasePopUp v-if="showModal" @detailsClosed="closeConfirmRequested">
        <div class="modal-body-container">
            <h4 class="text-center mt-4">{{confirmationMessage}}</h4>  
            <div class="d-flex justify-content-around col-6 align-self-center">           
                        
            <CButton type="button" class="mb-4 btn btn-primary align-self-center" @click="closeConfirmRequested">Confirm</CButton>
            <!-- <CButton type="button" v-if="cancelButton" class="mb-4 btn btn-primary align-self-center" @click="cancelConfirmRequested">Cancel</CButton> -->
            </div>
        </div>
    </BasePopUp>
</template>

<script>
import BasePopUp from './BasePopUp.vue';

export default {
    extends: BasePopUp,
    components: {BasePopUp},
    methods:{
        closeConfirmRequested(event)
        {       
            this.$emit("closeConfirmRequested");
        }
    },
    props:{
        confirmationMessage:
        {
            type: String,
            default: ''
        },
    },
}
</script>

<style scoped>
    .modal-body-container
    {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px;
        border-radius: 10px;    
        min-height: 200px;
    
    }
</style>