<template>
  <div>
    <div
      title="Choose an image"
      class="base-image-input col-12 col-lg-6"
      :style="{ 'background-image': `url(${imageData})` }"
      @click="$refs.fileInput.click()"
    >
      <span v-if="!imageData" class="d-flex flex-column placeholder">
        {{ "chooseImage" }}
        <pre class="m-0 text-muted">{{ ratioDisplay }}</pre>
        <pre class="m-0 text-muted">.jpeg/.png</pre>
      </span>
      <input
        ref="fileInput"
        type="file"
        style="display: none;"
        @change="onFileSelect"
      />
    </div>
    <BasePopUp
      v-if="showModal"
      v-model="showModal"
      @detailsClosed="showModal = false"
    >
      <VueCropper
        v-show="selectedFile"
        ref="cropper"
        :src="selectedFile"
        alt="Source Image"
        :aspectRatio="ratio"
        :initialAspectRatio="ratio"
      ></VueCropper>
      <CRow class="justify-content-between m-0 p-1">
        <CButton
          v-show="selectedFile"
          color="secondary"
          text
          @click="showModal = false"
          >Cancel</CButton
        >
        <CButton v-show="selectedFile" color="primary" @click="confirmClicked"
          >Crop</CButton
        >
      </CRow>
    </BasePopUp>
  </div>
</template>
<script>
import BasePopUp from "@/views/controls/popups/BasePopUp.vue";
import { mapState } from "vuex";
import axios from "axios";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
export default {
  components: { VueCropper, BasePopUp },
  props: {
    value: String,
    ratio: Number,
    ratioDisplay: String,
  },
  data() {
    return {
      mime_type: "",
      fileName: "",
      imageData: this.value,
      selectedFile: "",
      showModal: false,
    };
  },
  methods: {
    onFileSelect(e) {
      try {
        const file = e.target.files[0];
        const maxSize = 1024 * 1024 * 5;
        this.mime_type = file.type;
        this.fileName = file.name;

        // Validations
        if (typeof FileReader != "function") {
          throw "Sorry, FileReader API not supported";
        }

        if (
          this.mime_type != "image/jpg" &&
          this.mime_type != "image/jpeg" &&
          this.mime_type != "image/png"
        ) {
          throw "This file is invalid! -Accepted files: jpeg/png";
        }

        if (file.size > maxSize) {
          throw "This file is invalid! -Max-size: 5MB";
        }

        // Process
        this.showModal = true;

        const reader = new FileReader();
        reader.onload = (event) => {
          this.showModal = true;
          this.selectedFile = event.target.result;
          this.$refs.cropper.replace(this.selectedFile);
        };
        reader.readAsDataURL(file);
      } catch (err) {
        alert(err);
      }
    },
    confirmClicked() {
      this.imageData = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        blob.lastModifiedDate = new Date();
        // blob.lastModifiedDate = new moment().format('DD-MM-YYYY-h:mm:ss');
        blob.name = this.fileName;
        this.selectedFile = blob;
      }, this.mime_type);

      this.showModal = false;
    },
  },
  watch: {
    value: function(val) {
      this.imageData = val;
    },
    imageData: function(val) {
      this.$emit("input", this.imageData);
    },
    selectedFile: function(val) {
      this.$emit("fileChanged", this.selectedFile);
    },
    showModal: function(val) {
      if (!val) {
        this.$refs.fileInput.value = null;
      }
    },
  },
};
</script>
<style scoped>
.base-image-input {
  display: block;
  width: 200px;
  height: 200px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
  max-width: unset !important;
}
.placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

.placeholder:hover {
  background: #e0e0e0;
}

.file-input {
  display: none;
}
.input-group-append {
  height: fit-content;
}

img {
  width: 50%;
  height: 50%;
}

.user {
  width: 140px;
  height: 140px;
  border-radius: 100%;
  border: 3px solid #2e7d32;
  position: relative;
}
.profile-img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.icon {
  position: absolute;
  top: 10px;
  right: 0;
  background: #e2e2e2;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  text-align: center;
  color: #0000ff;
  font-size: 14px;
  cursor: pointer;
}
</style>
